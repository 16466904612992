import React from 'react';
import { Image, Space, Typography } from 'antd';
import nikkieGoldImage from '../../assets/images/nikkie-gold.jpg';
import szshGoldImage from '../../assets/images/szsh-gold.jpg';
import hsGoldImage from '../../assets/images/hs-gold.jpg';
import taiwanGoldImage from '../../assets/images/taiwan-gold.jpg';
import dowjonesGoldImage from '../../assets/images/dowjones-gold.jpg';
import englandGoldImage from '../../assets/images/england-gold.jpg';
import gemanyGoldImage from '../../assets/images/gemany-gold.jpg';
import russiaGoldImage from '../../assets/images/russia-gold.jpg';
import hanoiVegasVip from '../../assets/images/hanoi-vegasv.jpg';
import laoVegasVip from '../../assets/images/lao-vegasv.jpg';

const { Title } = Typography;

function HowToAnnounceCard() {
    return (
        <Typography style={{ fontSize: 16 }}>
            <Title style={{ textAlign: 'center' }}>การออกผลรางวัล</Title>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', marginBottom: 5 }}>
                <Image src={nikkieGoldImage} preview={false} style={{  width: '100%', maxWidth: 800 }} />
            </Space>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', marginBottom: 5 }}>
                <Image src={szshGoldImage} preview={false} style={{  width: '100%', maxWidth: 800 }} />
            </Space>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', marginBottom: 5 }}>
                <Image src={hsGoldImage} preview={false} style={{  width: '100%', maxWidth: 800 }} />
            </Space>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', marginBottom: 5 }}>
                <Image src={taiwanGoldImage} preview={false} style={{  width: '100%', maxWidth: 800 }} />
            </Space>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', marginBottom: 5 }}>
                <Image src={dowjonesGoldImage} preview={false} style={{  width: '100%', maxWidth: 800 }} />
            </Space>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', marginBottom: 5 }}>
                <Image src={englandGoldImage} preview={false} style={{  width: '100%', maxWidth: 800 }} />
            </Space>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', marginBottom: 5 }}>
                <Image src={gemanyGoldImage} preview={false} style={{  width: '100%', maxWidth: 800 }} />
            </Space>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', marginBottom: 5 }}>
                <Image src={russiaGoldImage} preview={false} style={{  width: '100%', maxWidth: 800 }} />
            </Space>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', marginBottom: 5 }}>
                <Image src={hanoiVegasVip} preview={false} style={{  width: '100%', maxWidth: 800 }} />
            </Space>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', marginBottom: 5 }}>
                <Image src={laoVegasVip} preview={false} style={{  width: '100%', maxWidth: 800 }} />
            </Space>
        </Typography>
    );
}

export default HowToAnnounceCard;